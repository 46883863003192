/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Title, SeparateLine, SeparateLineWrap, Text, PriceWrap } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"David Klema - Elektromontážní práce - Ceník"} description={"Nacenění zakázky bez možnosti prohlídky je velmi složité a z tohoto důvodu doporučujeme osobní schůzku. Ceny za práci jsou sestavovány individuálně dle náročnosti a požadavků zákazníka."} seoTitle={"Ceník elektroinstalace"}>
        <SiteHeader set="w3paydrlcin" currentLanguage={undefined} />

        <Column className="css-6ju72n --style2 --full" name={"uvod"} fullscreen={true} parallax={false} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/821da5f2755549a8b4ce0af20d8cfa66_s=350x_.JPG);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/821da5f2755549a8b4ce0af20d8cfa66_s=660x_.JPG);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/821da5f2755549a8b4ce0af20d8cfa66_s=860x_.JPG);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/821da5f2755549a8b4ce0af20d8cfa66_s=1400x_.JPG);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/821da5f2755549a8b4ce0af20d8cfa66_s=2000x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/821da5f2755549a8b4ce0af20d8cfa66_s=660x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/821da5f2755549a8b4ce0af20d8cfa66_s=1400x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38215/821da5f2755549a8b4ce0af20d8cfa66_s=3000x_.JPG);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5 --center pl--0" style={{"maxWidth":""}} anim={"6"} animS={"5"}>
              
              <Subtitle className="subtitle-box fs--26 w--600 swpf--uppercase ls--50" content={"<span style=\"color: var(--white);\">David Klema</span><br>"}>
              </Subtitle>

              <Title className="title-box fs--43 w--500 lh--1" content={"<span style=\"color: var(--white);\">Ušetřete si možné problémy s elektroinstalací.</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"nb4ev61vv8"} layout={"l3"}>
          
          <SeparateLineWrap style={{"paddingBottom":0,"marginTop":0,"paddingTop":0}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center" style={{"paddingTop":0,"paddingBottom":36}} name={"cenik"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1 pb--10 pt--10" style={{"maxWidth":""}} anim={"2"} animS={"5"} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":900}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"Ceník"}>
              </Title>

              <Text className="text-box fs--18" style={{"maxWidth":""}} content={"<span style=\"color: var(--white);\">Nacenění zakázky bez možnosti prohlídky je velmi složité a z tohoto důvodu doporučujeme osobní schůzku.<br>Ceny za práci jsou sestavovány individuálně dle náročnosti a požadavků zákazníka. </span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" content={"<span style=\"color: var(--white);\">&nbsp;&nbsp;Drobné opravy jsou prováděny dle hodinové sazby:</span>"}>
              </Text>

              <PriceWrap style={{"backgroundColor":"rgba(242, 246, 252, 1)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"<span style=\"color: var(--color-custom-1--95);\">Hodinová sazba</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"font-weight: bold; color: var(--color-custom-1--95);\">500 Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"rgba(242, 246, 252, 1)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"<span style=\"color: var(--color-custom-1--95);\">Pohotovostní hodinová sazba</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"font-weight: bold; color: var(--color-custom-1--95);\">850 Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"rgba(242, 246, 252, 1)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"<span style=\"color: var(--black);\">Doprava do 20 km<br>Sazba za 1 km<span style=\"font-weight: bold;\">&nbsp;</span></span><br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper >
                  
                  <Text className="text-box text-box--right" content={"<span style=\"font-weight: bold; color: var(--color-custom-1--95);\">Zdarma</span>"}>
                  </Text>

                  <Text className="text-box text-box--right" style={{"maxWidth":1000}} content={"<span style=\"font-weight: bold; color: var(--color-custom-1--95);\">10 Kč</span><br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"htg8hg3mt28"} layout={"l3"}>
          
          <SeparateLineWrap style={{"paddingBottom":0,"paddingTop":0}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"paddingBottom":0,"paddingTop":20}} layout={"l1"} name={"e604zd41zuk"}>
          
          <ColumnWrap className="column__flex --left el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"David Klema - Elektromontážní práce"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--30" content={"Kontakt"}>
              </Title>

              <Text className="text-box fs--18" content={"<span style=\"color: var(--white); font-weight: bold;\">Tovární 2446/2\n<br>Karviná Nové - Město<br>735 06<br>+420 739 294 592<br>davidklema@seznam.cz<br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}